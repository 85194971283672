import React from 'react'

const Footer = () => {
  return (
    <div className='bg-slate-900 w-full h-[150px] text-white flex justify-center items-center mb-0'>
      <div>
        @sachintha Chamindu All Rights Reserved
      </div>
    </div>
  )
}

export default Footer
